import React, { useRef, useState, useEffect } from 'react';
import './Login.css';

const Login = () => {
    const [error, setError] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    // const [highlight, setHighlight] = useState('');
    const [valid, checkValid] = useState(false);

    const usernameRef = useRef();
    const passwordRef = useRef();

    useEffect(() => {
        if(error){
            error.field.focus();
            // error.field.setHighlight('errorBckgrd');
        }else{
            // usernameRef.current.blur();
            // passwordRef.current.blur();
        }
        return () => {
            //cleanup class and box
            // passwordRef.className = "";
            // usernameRef.blur();
            // passwordRef.blur();
            //DO ALL THIS IN SUCCESS OF SUBMIT HANDLER
            //BY JUST RESETTING ERROR
        };

    }, [error])
    //alternatively, this enables login button for correct combo
    //if "%%", bypasses error handling as button disabled otherwise
    //uses username and password in its dependency array
    useEffect(()=>{
        if(username.length >= 1 && password.length >= 1){
            // if(username === 'trainer' || password === 'letmein1'){

            checkValid(true);
        }
    }, [username, password])

    const handleSubmit = (e) => {
        e.preventDefault();
        if(username === 'trainer' && password === 'letmein1'){
            setUsername('');
            setPassword('');
            setError(null);
        } else if(username !== 'trainer'){
            setError({
                message: 'Invalid username',
                field: usernameRef.current
            });
        } else if(password !== 'letmein1'){
            setError({
                message: 'Invalid password',
                field: passwordRef.current
            });
        }
    }
    return (
        <form>
            <label htmlFor="username">Username: </label>
            <input 
                type="text" 
                ref={ usernameRef }
                value={ username }
                onChange={ e => setUsername(e.target.value) }
                id="username" 
                // className={ () => setHighlight }
                // className={error ? "errorBckgrd": ""} 
                />
            <label htmlFor="password">Password: </label>
            <input 
                type="password" 
                ref={ passwordRef } 
                value={ password }
                onChange={ e => setPassword(e.target.value) }
                id="password" 
                // className={error ? "errorBckgrd": ""} 
                />
            <button 
                onClick={ handleSubmit} 
                disabled={!valid}
                >LOGIN
            </button>
            {error && 
                <div className="error">
                    { error.message }
                </div>
            }
        </form>
        // if no form tags then no keycode 13 submit behaviour
    )
}

export default Login;